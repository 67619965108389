.colaborador {
    position: relative;
    width: 280px;
    margin-bottom: 5%;
}

.colaborador .cabecalho {
    background-color: #F0F0F0;
    border-radius: 10px 10px 0px 0px;
}

.colaborador img {
    width: 100px;
    border-radius: 50%;
    position: relative;
    bottom: -50px;
}

.colaborador .rodape {
    background: #FFFFFF;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 10px 10px;
    padding-top: 50px;
    padding-bottom: 40px;
}

.colaborador .rodape h4 {
    color: #6278F7;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    margin-bottom: 8px;
}

.colaborador .rodape h5 {
    font-size: 18px;
    line-height: 22px;
    height: 10px;
    color: #212121;
    padding: 0 16px;
}

.container__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    line-height: 22px;
    color: #212121;
    padding-top: 22px;
    gap: 15px;
}

.container__data h6,
p {
    font-size: 18px;
    line-height: 22px;
    height: 10px;
    color: #212121;
    margin: 0;
}

.deletar {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #212121;
}

.deletar:hover {
    color: #F44336;
    cursor: pointer;
}

.favoritar {
    margin-top: 20px;
}

.favoritar svg:hover {
    cursor: pointer;
}