.time {
    text-align: center;
    padding: 32px;
    background-blend-mode: multiply;
    position: relative;
}

.times h1 {
    font-size: 35px;
    text-align: center;
}

.time h3 {
    font-size: 32px;
    font-family: 'Prata';
    border-bottom: 4px solid;
    display: inline-block;
    padding-bottom: 8px;
}

.time .colaboradores {
    display: flex;
    justify-content: space-around;
    margin-top: 32px;
    flex-wrap: wrap;
}

.input-cor {
    position: absolute;
    top: 20px;
    right: 50px;
}