.formulario-container {
    display: flex;
    justify-content: center;
    margin: 80px 50px;
    gap: 20px;
    flex-wrap: wrap;
}

.formulario-container .formulario {
    flex: 1;
    background: #F7F7F7;
    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 36px 64px;
}

.formulario-container .formulario h2 {
    font-weight: 400;
    font-size: 32px;
}