.lista-suspensa {
    position: relative;
    display: inline-block;
}

.lista-suspensa {
    margin: 24px 0;
    width: 100%;
}

.lista-suspensa label {
    display: block;
    margin-bottom: 8px;
    font-size: 24px;
}

.lista-suspensa select {
    background-color: #FFFFFF;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.06);
    width: 100%;
    border: none;
    font-size: 24px;
    padding: 24px;
    box-sizing: border-box;
    color: #757575;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.lista-suspensa select:hover {
    cursor: pointer;
}

.lista-suspensa::after {
    content: '';
    position: absolute;
    /* top: 80%; */
    right: 30px;
    top: 60%;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #757575;
    pointer-events: none;
}